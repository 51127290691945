<template>
  <NuxtImg
    :class="classObject"
    :alt="props.alt"
    :src="props.src"
    :style="props?.style"
    quality="100"
    loading="lazy"
    sizes="xs:100vw sm:100vw md:50vw lg:50vw xl:1920px"
  />
</template>

<script setup lang="ts">
enum Position {
  Left = 'left',
  Right = 'right',
}

interface PropType {
  alt?: string
  src?: string
  position?: string
  style?: string
}

const props = defineProps<PropType>()

const classObject = computed(() => ({
  'position-right': props.position == Position.Right,
  'position-left': props.position == Position.Left,
}))
</script>

<style lang="scss" scoped>
img {
  object-fit: cover;
  position: absolute;

  @media (max-width: $mobile) {
    width: 100% !important;
    left: 0px !important;
    right: 0px !important;
  }
}

.position-right {
  width: 50%;
  left: 50%;
  padding-left: 10px;
}

.position-left {
  width: 50%;
  right: 50%;
  padding-right: 10px;
}
</style>
